@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'WHRMono';
  src: url('public/fonts/WHR-Mono.woff2') format('woff2');
}

@font-face {
  font-family: 'WHRMonoBold';
  src: url('public/fonts/WHR-Mono-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.box-shadow-selected {
  box-shadow: 0 0 0 1px black;
}

.box-shadow-hovering {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.50);
}

.fade {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.fade-out {
  opacity: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@layer base {
  button {
    letter-spacing: inherit;
  }

  html {
    /* @apply font-whrMono text-black text-xs font-bold; */
    @apply font-whrMono text-black text-xs;

    @screen md {
      @apply text-[10px];
    }
  }

  body {
    @apply bg-white;
  }
}

@layer components {
  .linkTextNavigation {
    @apply hover:opacity-60 transition-all delay-75 ease-in-out;
  }

  .portableText {
    strong {
      font-family: 'WHR Mono Bold', monospace;
    }

    ol {
      list-style-type: decimal;
    }

    ol ol {
      list-style-type: lower-alpha;
    }

    ol ol ol {
      list-style-type: lower-roman;
    }

    ul {
      list-style: disc outside;
    }

    ul ul {
      list-style: circle outside;
    }

    ul ul ul {
      list-style: square outside;
    }
  }

  .select {
    align-items: center;
    background-color: theme("colors.black");
    border-radius: theme("borderRadius.full");
    color: theme("colors.black");
    display: flex;
    font-size: theme("fontSize.sm");
    font-weight: theme("fontWeight.bold");
    height: 2.5rem;
    justify-content: center;
    letter-spacing: theme("letterSpacing.normal");
    overflow: hidden;
    padding: theme("padding.4");
    transition: all 200ms ease-out;
  }

  .select:disabled {
    color: theme("colors.black");
  }

  .select:hover:not(:disabled) {
    opacity: 0.9;
  }
}

* {
  font-variant-ligatures: none;
}

html {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

/* .embla__slide {
  position: relative;
  flex: 0 0 100%;
} */

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

/* Hide the scroll bar */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Hide the scroll bar track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Hide the scroll bar thumb */
::-webkit-scrollbar-thumb {
  background: transparent;
}

.containernone {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.containerSmall {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10rem;
  padding-left: 10rem;
}

.link {
  text-decoration: none;
  transition: all 0.05s ease;

  &:hover {
    opacity: .6;
  }
}

/* Embla */
.embla_parent {
  /* --slide-height: 19rem; */
  --slide-spacing: 8px;
  overflow: hidden;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
}

.embla__viewport {
  /* height: 100%; */
  /* padding-top: 34px; */
  /* padding-bottom: 50px; */
  --slide-spacing: 8px;
  height: 100dvh;
  overflow: hidden;
}


.embla {
  overflow: hidden;
}

.embla__container {
  /* backface-visibility: hidden; */
  display: flex;
  /* touch-action: pan-x; */
  height: 100% !important;
  padding-top: 34px !important;
  padding-bottom: 50px !important;
  /* padding-bottom: 50px; */
  /* margin-left: calc(var(--slide-spacing) * -1); */
}

.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  display: flex;
  padding: 0 var(--slide-spacing);
}

.embla__slide_two {
  flex: 0 0 100%;
  min-width: 0;
  display: flex;
  padding: 0 var(--slide-spacing);
}

.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  @apply bg-white;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: flex-start; */
  position: sticky;
  bottom: 0px;
  left: 0px;
  /* width: 100%; */
}

.embla__current-info {
  padding: 1rem;
}

.embla__dots {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 2px;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
  /* gap: 1.4rem; */
  /* padding: 1rem; */
  /* margin-right: calc((2.6rem - 1.4rem) / 2 * -1); */
}

.embla__dot {
  /* -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5); */
  -webkit-tap-highlight-color: none;
  -webkit-appearance: none;
  appearance: none;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 12px;
  /* margin: 3px; */
  /* width: 75px; */
  /* border-color: rgb(167, 167, 167); */
  width: 60px;
  border-top: 2px solid rgb(167, 167, 167);
  transition: all 0.3s ease;

  @screen md {
    width: 20px;
  }

  &:hover {
    border-top: 2px solid black;
  }
}

.embla__dot--selected {
  /* background-color: black; */
  border-top: 2px solid black;
}

.spinnerLoader {
  transform-origin: center;
  animation: spinnerZ 0.75s step-end infinite;
}

@keyframes spinnerZ {
  8.3% {
    transform: rotate(30deg);
  }
  16.6% {
    transform: rotate(60deg);
  }
  25% {
    transform: rotate(90deg);
  }
  33.3% {
    transform: rotate(120deg);
  }
  41.6% {
    transform: rotate(150deg);
  }
  50% {
    transform: rotate(180deg);
  }
  58.3% {
    transform: rotate(210deg);
  }
  66.6% {
    transform: rotate(240deg);
  }
  75% {
    transform: rotate(270deg);
  }
  83.3% {
    transform: rotate(300deg);
  }
  91.6% {
    transform: rotate(330deg);
  }
  100% {
    transform: rotate(360deg);
  }
}